import { createStore, applyMiddleware, compose } from "redux"
import middleware from "./middleware"
import reducers from "./reducers"
import { initialize } from "./initialize"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const initialState = () => {
  // this is the hacked in bit for temporary saving.
  const localStorageState = localStorage.getItem("cycliccal")
  if (localStorageState) {
    return JSON.parse(localStorageState)
  }
  // end with temp hack.

  return {}
}

let store = createStore(
  reducers,
  initialState(),
  composeEnhancers(applyMiddleware(...middleware))
)

export function registerStore(newStore) {
  store = newStore
}

export function getCurrentState() {
  return store.getState()
}

initialize(store)

export default store

export const stateReflector = (state) => state
