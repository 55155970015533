import React, { useState } from "react"

import { Modal, Button } from "antd"

import LayoutPane from "pane/Layout"
import BetaBlurb from "./BetaBlurb"

import "./App.css"
import "antd/dist/antd.css"

function App() {
  const [isModalVisible, setIsModalVisible] = useState(true)
  const closeIt = () => setIsModalVisible(false)
  return (
    <>
      {isModalVisible && (
        <Modal
          title="Welcome to CyclicCal"
          visible={isModalVisible}
          onOk={closeIt}
          onCancel={closeIt}
          closable={false}
          footer={[
            <Button key="submit" type="primary" onClick={closeIt}>
              Let&apos;s go!
            </Button>,
          ]}
        >
          <BetaBlurb />
        </Modal>
      )}
      <LayoutPane />
    </>
  )
}

export default App
