import React from "react"

/*
  this is used in the Layout pane and is designed to be temporary - all it does is
  dump the state into localStorage and load it back into redux when loaded.

  Only refs to it should be in Layout.js and store/store.js
*/

import { Button } from "antd"

import { getCurrentState } from "store/store"

const STORAGE_KEY = "cycliccal"

const LocalStorageSaver = () => {
  return (
    <div>
      <Button
        onClick={() => {
          window.localStorage.removeItem(STORAGE_KEY)
        }}
      >
        Clear
      </Button>
      <Button
        type="primary"
        onClick={() => {
          window.localStorage.setItem(
            STORAGE_KEY,
            JSON.stringify(getCurrentState())
          )
        }}
      >
        Save
      </Button>
    </div>
  )
}

export default LocalStorageSaver
