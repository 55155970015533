import React, { useState } from "react"
import PropTypes from "prop-types"

import { Button, Drawer, Checkbox, Switch } from "antd"

import EventEditor from "./EventEditor"

import { connect } from "react-redux"
import * as eventActions from "./actions"
import { getSortedEvents } from "./utils"

import "./EventList.css"

const EventList = ({ events, updateEvent }) => {
  const [savedEventId, setSavedEventId] = useState(undefined)
  const [showHidden, setShowHidden] = useState(true)
  console.log("EL : ", events)
  const closeDrawer = () => {
    setSavedEventId(undefined)
  }
  return (
    <div className="event-list-container">
      <Switch
        className="display-switch"
        onChange={() => setShowHidden(!showHidden)}
        checked={showHidden}
        checkedChildren="show hidden events"
        unCheckedChildren="do not show hidden events"
      />
      <ul className="event-list">
        {events
          .filter((e) => e.visible || showHidden)
          .map((e) => (
            <li key={e.id}>
              <Checkbox
                checked={e.visible}
                onChange={() => updateEvent(e.id, { visible: !e.visible })}
              />
              <span onClick={() => setSavedEventId(e.id)}>{e.eventName}</span>
            </li>
          ))}
      </ul>

      <Button
        type="primary"
        onClick={() => {
          setSavedEventId(-1)
        }}
      >
        Add event
      </Button>
      <Drawer
        title="Event Editor"
        placement="left"
        closable={false}
        onClose={closeDrawer}
        visible={Boolean(savedEventId)}
        width={"75%"}
      >
        <EventEditor eventId={savedEventId} closeDrawer={closeDrawer} />
      </Drawer>
    </div>
  )
}

EventList.propTypes = {
  events: PropTypes.array,
  updateEvent: PropTypes.function,
}

const mapStateToProps = (state) => {
  return { events: getSortedEvents(state) }
}

export default connect(mapStateToProps, eventActions)(EventList)
