import React from "react"
import PropTypes from "prop-types"

import { Tag } from "antd"
import { connect } from "react-redux"

import { formatDate } from "utils/date"

import {
  repeatRuleInterpreter,
  labelRuleInterpreter,
  // FINAL_REPEAT_RULE_STRING,
} from "rule-editor/dist"

import { Calendar } from "utils/Calendar"
import "./CyclicCalendar.css"

function generateEventNamer(event) {
  if (event.labelRules && event.labelRules.length) {
    return labelRuleInterpreter(event.labelRules)
  } else {
    return () => ({ label: event.eventName, color: event.color })
  }
}

function onChange(value) {
  console.log("CHANGES TO : ", value)
}

const CyclicCalendar = ({ dates }) => {
  console.log("CC : ", dates)

  const dateCellRender = (value) => {
    const formattedDate = formatDate(value)
    if (dates[formattedDate] === undefined) {
      return null
    }
    console.log("RENDERS CELL : ", dates[formattedDate])
    return (
      <ul className="events">
        {dates[formattedDate].map((e) => (
          <li key={e.id}>
            <Tag color={e.name.color} style={{ width: "100%" }}>
              {e.name.label}
            </Tag>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <Calendar
      dateCellRender={dateCellRender}
      onChange={onChange}
      value={undefined}
    />
  )
}

const mapStateToProps = (state) => {
  const dates = {}

  const events = state.events
  Object.values(events).forEach((e) => {
    if (!e.visible) {
      return
    }
    console.log("E Ba: ", e)
    const repeatRule = repeatRuleInterpreter(e.repeatRules)
    const eventNamer = generateEventNamer(e)
    for (let i = 0; i < 365; i++) {
      const date = formatDate(repeatRule(state))
      const name = eventNamer()
      if (dates[date] === undefined) {
        dates[date] = []
      }
      dates[date].push({ date, name, color: e.color, id: e.id })
    }
  })
  return { dates }
}

CyclicCalendar.propTypes = {
  dates: PropTypes.object,
}

export default connect(mapStateToProps)(CyclicCalendar)
