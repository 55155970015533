import { SORT_DIR } from "./constants"

export const compareOnField = (objA, objB, field) => {
  return String(objA[field]).localeCompare(
    String(objB[field]),
    window.navigator.language,
    { numeric: true }
  )
}

export const numericCompareOnField = (objA, objB, field) => {
  const fieldA = objA[field] || 0
  const fieldB = objB[field] || 0
  if (fieldA > fieldB) {
    return 1
  } else if (fieldA < fieldB) {
    return -1
  } else {
    return 0
  }
}

export const matchComparator = (field, value) => {
  return (objA, objB) => {
    const matchA = objA[field] === value
    const matchB = objB[field] === value
    if (matchA === matchB) {
      return 0
    } else if (matchA) {
      return -1
    } else {
      return 1
    }
  }
}

export const boolComparator = (callback) => {
  return (objA, objB) => {
    const matchA = callback(objA)
    const matchB = callback(objB)

    if (matchA === matchB) {
      return 0
    } else if (matchA) {
      return -1
    } else {
      return 1
    }
  }
}

export const createComparator = (field, direction, numeric = false) => {
  const comparison = numeric ? numericCompareOnField : compareOnField

  if (direction === SORT_DIR.ASC) {
    return (objA, objB) => {
      return comparison(objA, objB, field)
    }
  } else {
    return (objA, objB) => {
      return -comparison(objA, objB, field)
    }
  }
}

export const comparatorSet = (comparators) => {
  return (objA, objB) => {
    for (const comparator of comparators) {
      const res = comparator(objA, objB)

      if (res) {
        return res
      }
    }

    return 0
  }
}

export const antSortDir = (sortDir) =>
  sortDir === SORT_DIR.DESC ? "descend" : "ascend"

export const antSortCol = (column, sortCol, sortDir) =>
  column === sortCol ? antSortDir(sortDir) : undefined
