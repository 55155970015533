import React from "react"

import LeftPane from "./Left"
import RightPane from "./Right"

import LocalStorageSaver from "./LocalStorageSaver"

import "./pane.css"

const Layout = () => {
  return (
    <div className="layout-pane">
      <LeftPane />
      <RightPane />
      <LocalStorageSaver />
    </div>
  )
}

export default Layout
