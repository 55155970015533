import React from "react"

const BetaBlurb = () => (
  <div>
    <p>
      Please pardon our dust, this is a soft-launch pre-release beta. We&apos;re
      testing the waters.
    </p>
    <p>
      CyclicCal is a new type of calendaring app designed to help you schedule
      all the really complicated things in your life. If you&apos; content with
      Google Calendar&apos;s scheduling and everything in your life cleanly
      happens on the 2nd of the month or every other Friday without issue, then
      you&apos;re already good to go and this is overkill.
    </p>
    <p>But for the rest of us, life gets real tricky real fast.</p>
    <p>
      Sure, your boss tells you that you get paid on the 15th and 30th of the
      month. But he actually meant that it&apos;s the 15th or the 30th unless
      it&apos;s a holiday. Then you get paid the day before. Oh! Or a weekend!
      You get paid on the Friday before! And of course, if the holiday is a
      Monday, then you get paid the previous Friday. Simple calendar apps just
      can&apos;t handle that.
    </p>
    <p>But we can.</p>
    <p>
      Just click the &quot;Add event&quot; button in the upper left to add
      something to your calendar and make it as complicated as you&apos;d like.
      There are some help popups in there to guide you.
    </p>
    <p>
      If you want to save your events after you&apos;ve set &apos;em up, just
      click the &quot;Save&quot; button in the lower left. That saves to your
      local browser (we&apos;re not keeping anything on the server). Clear it to
      delete your events.
    </p>
    <p>Let me know if it looks useful!</p>
  </div>
)

export default BetaBlurb
