import React from "react"
import PropTypes from "prop-types"

import { Form, Input, Button, Checkbox } from "antd"

import { connect } from "react-redux"
import * as eventActions from "./actions"
import { getSortedEvents } from "./utils"

import store from "store/store"

import {
  RepeatRuleEditor,
  newRepeatRule,
  LabelRuleEditor,
  setStore as setDebtinatorAtomStore,
  setDispatch as setDebtinatorAtomDispatch,
  ATOM_DISPATCH_KEY,
} from "rule-editor/dist"

setDebtinatorAtomStore(store)
setDebtinatorAtomDispatch(ATOM_DISPATCH_KEY.getEventsForAtom, (idx, atom) => {
  const events = getSortedEvents().map((event) => ({
    value: event.id,
    label: event.eventName,
  }))

  return {
    ...atom,
    options: events,
    default: events[0].value,
  }
})

const EventEditor = ({ event, updateEvent, removeEvent, closeDrawer }) => {
  console.log("EER : ", event)
  return (
    <div>
      <Form
        key={event.id}
        name="Event Editor"
        onFinish={(values) => {
          updateEvent(event.id, values)
          closeDrawer()
        }}
        initialValues={event}
      >
        <Form.Item label="Name" name="eventName" tooltip="Name your event">
          <Input />
        </Form.Item>
        <Form.Item
          label=" "
          name="labelRules"
          valuePropName="rules"
          trigger="setRules"
          tooltip={
            <>
              <p>Normally, the event name will display in the calendar.</p>
              <p>But some events are cyclical.</p>
              <p>
                Maybe you go to the gym every day, but alternate between arm day
                and leg day. That&apos;s what you do here. Add a new rule, and
                make it &quot;Arm Day&quot;. Then add a second rule and make it
                &quot;Leg Day&quot;. You can even color code them!
              </p>
              <p>
                Now your calendar won&apos;t just say &quot;Go to the Gym&quot;,
                it&apos;ll say Arm Day or Leg Day.
              </p>
            </>
          }
        >
          <LabelRuleEditor />
        </Form.Item>
        <Form.Item
          label="Show in calendar"
          name="visible"
          valuePropName="checked"
          tooltip={
            <>
              <p>If checked, this event will show up on your calendar.</p>
              <p>
                It can be handy to hide an event if you&apos;re only using it to
                define an event you actually care about.
              </p>
              <p>
                For example, you could create an event for Christmas (every year
                on December 25th), and then use that event to say that your
                payday should be moved backwards one day if it falls on
                Christmas. You can then hide Christmas from the calendar, but
                see that you get paid on the 24th that month.
              </p>
            </>
          }
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
          tooltip="Color code your events as desired"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Repeat Rules"
          name="repeatRules"
          valuePropName="rules"
          trigger="setRules"
          tooltip={
            <>
              <p>
                This is where the magic happens. Configure the rules to define
                your event.
              </p>
              <p>
                It may be easiest to just play with it - make adjustments to the
                rule. Have it start on a different day, or change when it
                repeats or how often.
              </p>
              <p>
                Click the + on the far right to add a sub rule. sub rules modify
                the parent rule in some manner - you can use them to modify the
                event date or skip over it if conditions are met. Or have it
                only land if conditions are met. There are lots of options.
              </p>
              <p>
                You can also add more, independent rules. So if you want your
                event to happen every week on Wednesday, but also every week on
                Thursday, just add an additional rule for it.
              </p>
            </>
          }
        >
          <RepeatRuleEditor />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          {Boolean(event.id !== -1) && (
            <Button
              type="primary"
              danger
              onClick={() => {
                console.log("EVENT : ", event)
                closeDrawer()
                removeEvent(event.id)
              }}
            >
              delete
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}

EventEditor.propTypes = {
  event: PropTypes.object,
  updateEvent: PropTypes.func,
  removeEvent: PropTypes.func,
  closeDrawer: PropTypes.func,
}

const mapStateToProps = (state, props) => {
  console.log("EE : ", props.eventId)
  return {
    event:
      props.eventId === -1 || props.eventId === undefined
        ? {
            repeatRules: newRepeatRule(),
            visible: true,
          }
        : state.events[props.eventId],
  }
}

export default connect(mapStateToProps, eventActions)(EventEditor)
