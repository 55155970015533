import React from "react"

import EventList from "event/EventList"

import "./pane.css"

const LeftPane = () => {
  return (
    <div className="left-pane">
      <EventList />
    </div>
  )
}

export default LeftPane
