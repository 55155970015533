import React from "react"

import CyclicCalendar from "CyclicCalendar"

const RightPane = () => {
  return (
    <div className="right-pane">
      <CyclicCalendar />
    </div>
  )
}

export default RightPane
