import format from "date-fns/format"
import parse from "date-fns/parse"
import addDays from "date-fns/addDays"
import addWeeks from "date-fns/addWeeks"
import getDaysInMonth from "date-fns/getDaysInMonth"
import getDay from "date-fns/getDay"
import getDate from "date-fns/getDate"
import isSunday from "date-fns/isSunday"
import isSaturday from "date-fns/isSaturday"
import startOfToday from "date-fns/startOfToday"

export const DATE_FORMAT = "yyyy-MM-dd"

export const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export function todayAtMidnight() {
  return startOfToday()
}

export function todayAtMidnightString() {
  return formatDate(todayAtMidnight())
}

export function formatDate(date) {
  return format(date, DATE_FORMAT)
}

export function parseDateString(dateString, dateFormat = DATE_FORMAT) {
  return parse(dateString, dateFormat, todayAtMidnight())
}

export function getWeekDayFromStart({
  startOfMonth: date,
  targetDay,
  targetWeek,
}) {
  const day = getDay(date)
  // if the target day is later in the week, then we advance to it.

  if (targetDay >= day) {
    date = addDays(date, targetDay - day)
  } else {
    date = addDays(date, 7 - (day - targetDay))
  }
  if (targetWeek > 1) {
    date = addWeeks(date, targetWeek - 1)
  }
  return date
}

export function getWeekDayFromEnd({
  startOfMonth: date,
  targetDay,
  targetWeekNum,
}) {
  const day = getDay(date)
  // if the target day is later in the week, then we advance to it.
  if (targetDay >= day) {
    date = addDays(date, targetDay - day)
  } else {
    date = addDays(date, 7 - (day - targetDay))
  }
  // okay, now we've made it to the first xday of the month.
  // return formatDate(date);
  // so we're gonna get the date of the month.
  const dateInMonth = getDate(date)
  const daysInMonth = getDaysInMonth(date)

  // and use it to find the last xday of the month
  const deltaToLastWeek = Math.floor((daysInMonth - dateInMonth) / 7)

  date = addWeeks(date, deltaToLastWeek - targetWeekNum)

  return date
}

export function getClosestWeekday(val) {
  if (isSunday(val)) {
    return addDays(val, 1)
  } else if (isSaturday(val)) {
    return addDays(val, -1)
  } else {
    return val
  }
}

export function addDayCardinality(label) {
  if (label.match(/(^|[^1])1$/)) {
    label += "st"
  } else if (label.match(/(^|[^1])2$/)) {
    label += "nd"
  } else if (label.match(/(^|[^1])3$/)) {
    label += "rd"
  } else {
    label += "th"
  }
  return label
}

export function formatWeeksFromEnd(day, daysInMonth) {
  const lastWeekNum = Math.floor((daysInMonth - day) / 7)

  switch (lastWeekNum) {
    case 0:
      return "last"
    case 1:
      return "2nd to last"
    case 2:
      return `3rd to last`
    case 3:
      return "4th to last"
    case 4:
      return "5th to last"
    default:
      throw new Error(`Cannot calculate weekNum from ${day}, ${daysInMonth}`)
  }
}
