import { UPDATE_EVENT, REMOVE_EVENT } from "./constants"

export const updateEvent = (id, event) => {
  return {
    type: UPDATE_EVENT,
    payload: { id, event },
  }
}

export const removeEvent = (id) => {
  return {
    type: REMOVE_EVENT,
    payload: { id },
  }
}
