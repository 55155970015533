import { REMOVE_EVENT, UPDATE_EVENT } from "./constants"

const INITIAL = {}

const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case UPDATE_EVENT: {
      const {
        id = 1 + Math.max(0, ...Object.keys(state)),
        event,
      } = action.payload
      return {
        ...state,
        [id]: { ...(state[id] || {}), ...event, id },
      }
    }

    case REMOVE_EVENT: {
      const { id } = action.payload
      const newState = { ...state }
      delete newState[id]
      return newState
    }
    default:
      return state
  }
}

export default reducer
